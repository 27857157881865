import React from 'react'
import Experiences from '../Experiences/Experiences'

function Experience() {
    return (
        <>
            <Experiences />
        </>
    );
}

export default Experience;