import React from 'react'
import Info from '../Info/Info'

function AboutMe() {
  return (
    <>
    <Info />
    </>
  );
}

export default AboutMe