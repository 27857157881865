import React from 'react'
import HomeContent from '../HomeContent/HomeContent';

function Home() {
  return (
    <>
        <HomeContent />
    </>
  );
}

export default Home;